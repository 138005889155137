<template>
  <div>
    <el-table :data="schedule" class="hoverable">
      <el-table-column type="expand">
        <template slot-scope="props">
          <all-orders-view
            :loading="loading"
            :service="service"
            :show-personnel-column="false"
            :orders="props.row.orders"
          />
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Rider</span>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.name }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Phone Number</span>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.phone_number }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Company</span>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.company }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Location Area</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.locationarea }}
          </p>
        </template>
      </el-table-column>
      <el-table-column v-if="service.includes('pickup')" width="200">
        <template slot="header">
          <div class="table--header">
            <span>Pickups completed</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.completed_pickups }} /
            {{ scope.row.rider.total_pickups }}
          </p>
        </template>
      </el-table-column>
      <el-table-column v-else width="200">
        <template slot="header">
          <div class="table--header">
            <span>Deliveries completed</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.rider.completed_deliveries }} /
            {{ scope.row.rider.total_deliveries }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Date</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatDate(scope.row.rider.date, "m do, y") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Trip start time</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatTime(scope.row.rider.start_trip) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Trip end time</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatTime(scope.row.rider.end_trip) }}
          </p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AllOrdersView from "./AllOrdersView";

export default {
  name: "RidersView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
    schedule: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    AllOrdersView,
  },
  data() {
    return {};
  },
};
</script>
